import React, {useEffect} from 'react';
import s from "./Loader.module.scss";
import classNames from "classnames";

const PageLoader = ({}) => {

	useEffect(() => {
		document.body.style.overflow = "hidden"

		return () => {
			document.body.style.overflow = "auto"
		}
	}, [])

	return (
		<div className={classNames(s.wrapper, s.wrapper_page)}>
			<div className={s.loader}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default PageLoader;