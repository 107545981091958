import {create} from "zustand";
import API from "../api";

interface AuthData {
	profile_title?: string,
	profile_link?: string,
	profile_image?: string | null,
	profile_description?: string
}

interface AuthStore {
	token: string | null
	data: AuthData

	setToken: (token: string) => void
	fetch: () => Promise<void>
	setData: (data: AuthData) => void
	updateProfile: () => Promise<void>
	logout: () => void
}

const useAuthStore = create<AuthStore>((set) => ({
	token: localStorage.getItem("token") || null,
	data: {},
	isAuth: false,

	setToken: (token) => {
		set({token})
	},
	fetch: async () => {
		try {
			const response = await API.auth();
			set({data: response})
		} catch (e: any) {
			console.log(e)
		}
	},
	setData: (newData) => {
		const data = useAuthStore.getState().data;
		set({data: {...data, ...newData}})
	},
	updateProfile: async () => {
		const data = useAuthStore.getState().data;
		delete data.profile_image;
		// @ts-ignore
		await API.updateProfile(data)
	},
	logout: () => {
		localStorage.removeItem("refresh")
		localStorage.removeItem("token")
		set({token: null})
	}
}));

export default useAuthStore;