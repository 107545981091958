import axios from "axios";
import {AppearanceData} from "../models/AppearanceData";
import {LinkType} from "../models/LinkTypes";
import useAuthStore from "../store/auth";
import toast from "react-hot-toast";

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
    while (failedQueue.length > 0) {
        const [promiseResolve, promiseReject] = failedQueue.shift();
        if (error) {
            promiseReject(error);
        } else {
            promiseResolve(token);
        }
    }
};

export const instanceWithoutAuth = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "Accept-Compression": "gzip",
    },
    withCredentials: true
})

instanceWithoutAuth.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    if (error.response.status === 500) {
        toast.error('An unexpected error occurred.');
    }

    throw error;
});

export const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        "Accept-Compression": "gzip",
    }
})

instance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    return config;
});

instance.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 500) {
        toast.error('An unexpected error occurred.');
    }

    if (error.response.status == 401 && error.config && !error.config._isRetry) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push([resolve, reject]);
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return instance(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            });
        }

        originalRequest._isRetry = true;
        isRefreshing = true;

        try {
            const response = await API.refresh(localStorage.getItem("refresh") || "")
            localStorage.setItem("token", response.access);
            instance.defaults.headers.common['Authorization'] = 'Bearer ' + response.access;
            processQueue(null, response.access);
            return instance.request(originalRequest);
        } catch (e) {
            processQueue(e, null);
            console.log("Unauthorized")
            localStorage.removeItem("refresh")
            const authStore = useAuthStore.getState()
            authStore.setToken("")
            throw e;
        } finally {
            isRefreshing = false;
        }
    }
    throw error;
});

const API = {
    // AUTH
    async register(email: string, profile_link: string, password: string) {
        const response = await instanceWithoutAuth.post(`/auth/register/`, {email, profile_link, password})
        return response.data
    },
    async login(email: string, password: string) {
        const response = await instanceWithoutAuth.post(`/auth/login/`, {email, password})
        return response.data
    },
    async platformLogin(platform: string, params: string) {
        const response = await instanceWithoutAuth.get(`/auth/${platform}/callback/?${params}`)
        return response.data
    },
    async refresh(refreshToken: string) {
        const response = await instanceWithoutAuth.post(`/auth/token/refresh/`, {refresh: refreshToken})
        return response.data
    },
    async auth() {
        const response = await instance.get(`/user/profile/`)
        return response.data
    },

    // PROFILE
    async updateProfile({profile_title, profile_link, profile_image, profile_description}: {
                            profile_title?: string,
                            profile_link?: string,
                            profile_image?: string,
                            profile_description?: string
                        }
    ) {
        const response = await instance.patch(`/user/profile/`, {
            profile_title,
            profile_link,
            profile_image,
            profile_description
        })
        return response.data
    },

    // APPEARANCE
    async getAppearanceSettings() {
        const response = await instance.get<AppearanceData>(`/user/appearance/`)
        return response.data
    },
    async updateAppearanceSettings({
                                       button_type,
                                       button_font_color,
                                       button_background_color,
                                       button_hover_color,
                                       button_font,
                                       background_color,
                                       profile_font
                                   }: AppearanceData
    ) {
        const response = await instance.patch(`/user/appearance/`, {
            button_type,
            button_font_color,
            button_background_color,
            button_hover_color,
            button_font,
            background_color,
            profile_font
        })
        return response.data
    },

    // LINKS
    async getLinks() {
        const response = await instance.get(`/user/links/all`)
        return response.data
    },
    async updateLink(id: string, data: any) {
        const response = await instance.patch(`/user/links/${id}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return response.data
    },
    async createLink(url: string, priority: number) {
        const response = await instance.post(`/user/links/`, {title: "New link", url, priority})
        return response.data
    },
    async deleteLink(id: string) {
        const response = await instance.delete(`/user/links/${id}/`)
        return response.data
    },
    async createSpecialLink(type: LinkType, data: any) {
        let response;
        switch (type) {
            case "twitch":
                response = await instance.post(`/user/links/special/${type}/`, data)
                break;
            case "discord":
                response = await instance.post(`/user/links/special/${type}/`, data)
                break;
            default:
                response = await instance.post(`/user/links/special/${type}/`, data)
                break;
        }

        return response.data
    },
    async deleteSpecialLink(type: LinkType, id: string) {
        const response = await instance.delete(`/user/links/special/${type}/${id}/`)
        return response.data
    },
    async updateSpecialLink(type: LinkType, id: string, data: any) {
        const response = await instance.patch(`/user/links/special/${type}/${id}/`, {...data, description: "fff"}, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return response.data
    },

    // FETCH LINK
    async getLink(link: string) {
        const response = await instanceWithoutAuth.get(`/links/view/${link}`)
        return response.data
    },

    // TEMPLATES
    async getThemes() {
        const response = await instanceWithoutAuth.get(`/themes`)
        return response.data
    },
    async applyTheme(id: number) {
        const response = await instance.post(`/themes/apply/${id}/`)
        return response.data
    },
}

export default API