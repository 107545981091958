import React, {lazy, Suspense, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Navigate, Outlet, RouterProvider} from "react-router-dom";
import './styles/globals.scss'
import {Toaster} from "react-hot-toast";
import useAuthStore from "./store/auth";
import PageLoader from "./components/UI/Loader/PageLoader";

const Login = lazy(() => import("./pages/login"));
const Home = lazy(() => import( "./pages/home"));
const Pricing = lazy(() => import( "./pages/pricing"));
const Links = lazy(() => import( "./pages/links"));
const Templates = lazy(() => import( "./pages/templates"));
const Profile = lazy(() => import( "./pages/profile"));
const Tos = lazy(() => import( "./pages/tos"));
const Admins = lazy(() => import( "./pages/admins"));
const Settings = lazy(() => import( "./pages/settings"));
const Tools = lazy(() => import( "./pages/tools"));
const Signup = lazy(() => import( "./pages/signup"));
const Link = lazy(() => import( "./pages/link"));
const Callback = lazy(() => import( "./pages/callback"));


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

export const ProtectedRoute = () => {
    const {token} = useAuthStore()

    if (!token) {
        return <Navigate to="/login" replace={true}/>;
    }

    return <Outlet/>;
};

const Routes = () => {
    const auth = useAuthStore()

    const publicRoutes = [
        {
            path: "/",
            element: <Home/>,
        },
        {
            path: "/pricing",
            element: <Pricing/>,
        },
        {
            path: "/links",
            element: <Links/>,
        },
        {
            path: "/templates",
            element: <Templates/>,
        },
        {
            path: "/profile",
            element: <Profile/>,
        },
        {
            path: "/tos",
            element: <Tos/>,
        },
        {
            path: "/admins",
            element: <Admins/>,
        },
        {
            path: "/:link",
            element: <Link/>,
        },
        {
            path: "/callback/:platform",
            element: <Callback/>,
        },
    ];

    const unprotectedRoutes = [
        {
            path: "/login",
            element: <Login/>,
        },
        {
            path: "/signup",
            element: <Signup/>,
        },
    ];

    const protectedRoutes = [
        {
            path: "/",
            element: <ProtectedRoute/>,
            children: [
                {
                    path: "/settings",
                    element: <Settings/>,
                },
                {
                    path: "/tools",
                    element: <Tools/>,
                },
            ]
        }
    ];

    const router = createBrowserRouter([...publicRoutes,
        ...(!auth.token ? unprotectedRoutes : unprotectedRoutes.map(route => ({
            ...route,
            element: <Navigate to="/tools"/>,
        }))),
        ...protectedRoutes]);

    useEffect(() => {
        if (auth.token) {
            localStorage.setItem('token', auth.token);
            auth.fetch()
        } else {
            localStorage.removeItem('token')
            auth.setData({})
        }
    }, [auth.token]);

    return <RouterProvider router={router}/>;
}

root.render(
    <>
        <Toaster toastOptions={{style: {fontSize: "1.5rem"}}}/>
        <Suspense fallback={<PageLoader/>}>
            <Routes/>
        </Suspense>
    </>
);

reportWebVitals();